require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        }else if (e.target.classList.contains('icon')) {
            toggleMobileMenu();
        }
    });
}

function scrollTo(e) {

    typeof e.preventDefault === 'function' && e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);

    scrollToElement(element);

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }

    toggleMobileMenu(false);
}

function scrollToElement(element, behavior = 'smooth') {

    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    const finalOffset = element.getBoundingClientRect().top + scrollTop;

    window.scrollTo({
        top: finalOffset,
        behavior
    });
}

function toggleMobileMenu(state) {

    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.site-header');
    const condition = hasState ? !!state : !header.classList.contains('nav-visible');

    condition
        ? header.classList.add('nav-visible')
        : header.classList.remove('nav-visible');
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}